/* Hide all buttons within the AudioRecorder component */
.hidden-audio-icon button,
.hidden-audio-icon .react-audio-voice-recorder-button,
.hidden-audio-icon svg,
.hidden-audio-icon .icon-class {
  display: none !important;
}

.audio,
audio {
  display: none !important;
}

.grey-label-700 {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-decoration-skip-ink: none;
  color: #6c736c;
}

.analyse-row-style {
  /* height: 90px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.analyse-table-row {
  height: 90px;
  border: 1px solid #e8e8e8;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background-color: #FFFFFF;
}

.analyse-cell-text {
  font-weight: 400;
  font-size: 14px;
  color: #6c736c;
  letter-spacing: -0.5px;
  margin: 0;
}


.custom-switch-analyse {
  outline: none !important;
  box-shadow: none !important;
  padding: 0px 10px;
  border-radius: 5px;
  margin-top: -5px;
}

.custom-switch-analyse .form-check-input:not(:checked):focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important;
}

.custom-switch-analyse .form-switch .form-check-input {
  color: white !important;
  background-color: white !important;
  width: 46px;
  height: 25px;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  cursor: pointer;
}

.custom-switch-analyse .form-check-input {
  background-color: #d0ccbb !important;
  border-color: #d0ccbb !important;
}

.custom-switch-analyse .form-check-input:checked {
  background-color: #78cd32 !important;
  border-color: #78cd32 !important;
}

.form-control:focus {
  box-shadow: none;
}

.custom-switch-analyse .form-check-input:checked::before,
.custom-switch-analyse .form-check-input::before {
  background-color: white !important;
  border-color: #d0ccbb !important;
  box-shadow: none !important;
}

.custom-switch-analyse .form-check-input:active,
.custom-switch-analyse .form-check-input:active:focus {
  outline: none !important;
  box-shadow: none !important;
}

.graph-container {
  scrollbar-width: none !important; /* For Firefox */
  -ms-overflow-style: none !important; /* For Internet Explorer and Edge */
}

.graph-container::-webkit-scrollbar {
  display: none !important; /* For Chrome, Safari, and Edge */
}

@media screen and (min-width: 750px) {
  .doc-rows-container{
    overflow-y: auto;
    max-height: calc(100dvh - 310px) !important;
  }
}

@media screen and (max-width: 750px) {
  .doc-rows-container{
    overflow-y: auto;
    max-height: calc(100dvh - 400px) !important;
  }
}

@media screen and (min-width:1250px) {
  .width-controller {
    min-width: 1138px !important;
    

  }
}

@media  screen and (max-width:1250px) {
  .width-controller {
    min-width: 100% !important;
   
  }
}



