.process-table-h4 {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.5px;
  color: #6C736C;
  /* padding-top: 2.5px; */
  margin-bottom: 0px !important;
}

.prop-val-p {
  /* color: black; */
  font-weight: 400;
  font-size: 14px;
  color: #6c736c;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 2px 2px;
}

.custom-invoice-canvas-menu div ul li {
  font-weight: 600;
  letter-spacing: -0.5px;
}

.skeleton-invoice {
  background-color: #e0e0e0;
  background-image: linear-gradient(
    90deg,
    #ffffff 20%,
    #d0d4e2 50%,
    #ffffff 80%
  );
  background-size: 200% 100%;
  animation: skeleton-loading 3.3s infinite;
  border-radius: 15px; /* Optional, for rounded corners */
  height: 265.5px;
  margin-bottom: 8px;
  border: 1px solid #e8e8e8;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Remove any label spacing in case a label exists */
.custom-control-label {
  margin: 0;
  padding-left: 0;
}

/* Style the custom checkbox square */
.custom-control-label::before {
  display: inline-block !important;
  width: 20px !important;
  height: 20px !important;
  background-color: transparent !important;
  border: 1px solid #7d7b71 !important;
  border-radius: 4px !important;
  transition: none !important;
  box-shadow: none !important; /* Remove any default shadows */
}

/* When checked, set the background but keep the border unchanged */
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ff690f !important;
  border: 1px solid #7d7b71 !important;
}

/* Remove focus, hover, and active outlines and shadows */
.custom-control-label:active::before,
.custom-control-label:hover::before,
.custom-control-label:focus::before,
.custom-control-label:focus-visible::before,
.custom-control-input:focus ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before {
  box-shadow: none !important;
  outline: none !important;
}

.form-check-input:focus{
  box-shadow: none !important;
  border-color: #7d7b71 !important;
}

.custom-ul {
  padding-left: 1.4rem !important;
  margin-left: 0px !important;
}

.li_letterSpacing {
  letter-spacing: 0px !important;
}

.hr_customization {
  margin-top: 0px !important;
  margin-bottom: 12px !important;
  max-width: 90%;
  height: 1.3px;
  background-color: #E8E8E8;
  

}
