.quick-setup-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    width: 523px;
    /* min-height: 652px; */
    max-height: fit-content;
    background-color: white;
    border-radius: 15px;;
    margin: 15px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
    border:1px solid #E8E8E8;
  
}

@media screen and (max-width: 600px){
    .quick-setup-main-container{
        width: 90%;
    }    
}

.success-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    width: 523px;
    min-height: 474px;
    background-color: white;
    border-radius: 15px;;
    padding-bottom: 5%;
    margin: 15px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
}

.success-parent-container{
    height: fit-content;
    display: flex;
    justify-content: center;
    
    /* align-items: center; */
}

.button-padding{
    padding: 130px 30px 30px 30px;
}

@media screen and (max-width:600px) {

    .success-parent-container{
        width: 100%;
    }

    .button-padding{
        padding:110px 30px 30px 30px !important;
    }

    .success-main-container{
        width: 90%;
        margin: 15px auto;
        height: fit-content;
    }

    .plan-description{
        padding: 0px 30px;
    }
    
}

.success-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px auto 20px auto;
    height: max-content ;
}

.thank-you-message{
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -1px;
    text-align: center;
    padding: 15px 25px 0px 25px;
}

.success-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.custom-button {
    letter-spacing: -1px;
    display: inline-block;
    height: 50px;
    line-height: 15px;
    padding: 15px 20px;
    border: 2px solid #fe6100;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    background-color: #fe6100;
  }

  .button-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    padding: 0px 30px;
}


.auth-labels{
    font-size: 12px;
    font-weight: 700;
    line-height: 21.04px;
    letter-spacing: -0.02em;
    color: #1E1D1B;
    /* text-align: left; */
    margin-bottom: 2px !important;
}

.profile-setup-body-text{
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.03em;
}