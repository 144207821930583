.custom-input-container-analyse {
  width: calc(100%);
  height: 32px;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #e8e8e8;
}

.custom-input-analyse {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  font-size: 14px;
  color: #000;
}

.custom-input-analyse::placeholder {
  color: #A4A4A4;
  font-weight: 400;
  font-size: 14px;
}

.custom-dropdown-filter {
  width: calc(100%);
  margin: 10px 15px 5px 15px;
  background: inherit;
  border: none !important;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.custom-dropdown-filter:hover {
  border: none !important;
}

.custom-dropdown-filter:focus-visible {
  border: none !important;
  outline: none !important;
}

.file-ul-responsive-size{
  max-height: 140px;
  overflow-y: auto;
}

@media screen and (max-width: 900px) {
  .file-ul-responsive-size {
    width: 100%;
    margin: 0px;
  }
}

@media screen and (min-width: 900px) {
  .file-ul-responsive-size {
    width: calc(91%) !important;
    margin: 0px 4.5% !important;
  }
}

.skeleton-document {
  width: 100%;
  background-color: #e8e8e8;
  border-radius: 15px;

}

.view-doc-max-height{
  max-height: calc(100dvh - 480px) !important;
}

@media screen and (max-width: 750px) {

  .view-doc-max-height{
    max-height: calc(100dvh - 570px) !important;
  }
  
}